import { SSE } from "./sse.js"
// const CHAT_API_ENDPOINT = "http://localhost:8000/api/chat/completion_nonstream";
const CHAT_API_ENDPOINT = 'https://sqlgenius-server.vercel.app/api/chat/completion_nonstream'


const getAPIStreamSourceChat = (prompt, { model = 'gpt-3.5-turbo', max_tokens = 500, stop = '', suffix = null, temperature=0.0 } = {}) => {
    //console.log("Stream source! Prompt: ", prompt)
    let source = new SSE(CHAT_API_ENDPOINT, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        payload: JSON.stringify({
            "max_tokens": max_tokens,
            "messages": [{ "role": "user", "content": prompt }],
            "temperature": temperature,
            "model": model,
            "stream": true,
        })
    })
    return source
}

const getAPICompletion = async (prompt, {} = {}) => {
    let response = await fetch(
        CHAT_API_ENDPOINT,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "prompt": prompt,
            })
        }
    )
    response = await response.json()
    console.log(response)
    const output = response['choices'][0]['message']['content']
    return output
}

export { getAPIStreamSourceChat, getAPICompletion}