import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import StyledFirebaseAuth from "../StyledFirebaseAuth.js";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


function LoginDialog(props) {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth='xs' onClose={handleClose} open={open} >
      <DialogTitle>Sign Up or Log In
      <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

      </DialogTitle>
      <StyledFirebaseAuth/>
      <div className='firebase-auth-container' />
    </Dialog>
  );
}

export default LoginDialog;