import React from "react"; 
import { Typography, Button, Box, Container} from "@mui/material";

const SplashPage = ({onButtonClick}) => {
  
  return (
    <Box className={'splashBg'}>
      <Box className={'centerHero'}>
        <h1>Let SQL Genius write SQL for you</h1>
        <span className={'subtitleText'}>(so you can go outside)</span>    
        <Button color='secondary' sx={{borderRadius:2, fontSize:'2.1em', textTransform:'none'}} variant='contained' onClick={onButtonClick}>Check it out</Button>  
      </Box>
      <Box className={'spacer'} />
    </Box>
  );
};

export default SplashPage;