import { Render } from '@9gustin/react-notion-render'
import { useEffect, useState } from 'react'
import { List, ListItem, ListItemButton, Link } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import {
  Navigate,
  useParams,
} from 'react-router-dom'
import './blog.css'
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import firebase from 'firebase/compat/app';
import { useNavigate } from 'react-router-dom'

const { Client } = require("@notionhq/client")

// Initializing a client
const notion = new Client({
  auth: process.env.NOTION_TOKEN,
})


//import { getBlocks, getDatabase } from '../services/notion'

const BlogPost = ({ pageId }) => {
  const [blocks, setBlocks] = useState(null)
  const [page, setPage] = useState(null)


  useEffect(() => {
    var getBlocksAndPage = firebase.functions().httpsCallable('getBlocksAndPage');
    getBlocksAndPage({ pageId: pageId })
      .then((result) => {
        // Read result of the Cloud Function.
        console.log(result.data['blocks'])
        setBlocks(result.data['blocks'])
        setPage(result.data['page'])
      });

  }, [pageId])

  return (
    <div>
      <Render blocks={blocks} classNames />
    </div>
  )
}

/*const blogPosts = [
  { title: 'First 5 Things to Learn About SQL in 2023', pageId: '5c71f01131784a82877f530a92b0a330' },
  { title: 'How AI Can Make Learning SQL Easier in 2023', pageId: '5d64437d337b4c199dbf0307c7f4f9f1' },
  { title: 'How to Get a Table\'s Schema in Postgres SQL', pageId: 'ef7b7df19b4c45d382527a8a1df0f4d0' }
]*/

const BlogIndexPage = () => {
  const navigate = useNavigate()
  const { routePostId } = useParams()
  let initialPageId = null
  if (routePostId) {
    initialPageId = routePostId
  }

  const getInitialPageIndex = (pageId) => {
    let i = 0
    for (let p in blogPosts) {
      if (p.id == pageId) { }
      return i
      i++
    }
    return i
  }


  const [selectedPageId, setSelectedPageId] = useState(initialPageId)
  const [selectedPageIndex, setSelectedPageIndex] = useState(0)
  const [blogPosts, setBlogPosts] = useState([])


  //console.log(`routePageId: ${routePostId}`)

  useEffect(() => {
    //firebase.functions().useEmulator('localhost', 5001);

    console.log('getting database')

    var getDatabase = firebase.functions().httpsCallable('getDatabase')
    getDatabase({id:'8bee4701b9a94356be98a9f489bffc4f'}).then(({data}) => {
      setBlogPosts(data['database'])
    })
  },[])


  useEffect(() => {
    if (blogPosts.length == 0) return
    for (const propKey in blogPosts[0]['properties']) {
      console.log(`${propKey} : ${blogPosts[0]['properties'][propKey]}`)
    }
    setSelectedPageIndex(getInitialPageIndex(selectedPageId))
    if (selectedPageId== null) setSelectedPageId(blogPosts[selectedPageIndex].id)
  }, [blogPosts])


  const handleRuleClick = (event, pageId, index) => {
    event.preventDefault()
    setSelectedPageId(pageId)
    setSelectedPageIndex(index)
    navigate('/blog/'+pageId)
  }

  let post = null
  if (blogPosts.length > 0) {
    post = blogPosts[selectedPageIndex]
  }

  return (
    <div className={'flexContainer flexRow flexItem'}>
      {post &&
      <Helmet>
        <title>{blogPosts.length > 0 && blogPosts[selectedPageIndex].title}</title>
        <meta name="description" content={post.properties.Meta.rich_text[0].plain_text} />
        <meta name='keywords' content={post.properties.Keywords.rich_text[0].plain_text} />
        <meta property="og:type" content="article" />  
      </Helmet>
      }
      <div className={'flexContainer flexRow flexItem'}>
        <div className={'flexContainer flexColumn flexItem sidebar'}>
          {<List>
            {blogPosts.map((post, i) => (
              <ListItemButton component={Link} href={'/blog/' + post.id} key={post.id} selected={selectedPageId == post.id} onClick={(e) => handleRuleClick(e, post.id, i)}>{post.properties.Name.title[0]?.plain_text}</ListItemButton>
            ))}
          </List>}
        </div>

        <div className={'flexContainer flexColumn flexItem blog'}>
          {selectedPageId && blogPosts && <BlogPost pageId={selectedPageId} />}
        </div>
      </div>
    </div>
  )
}

export { BlogIndexPage, BlogPost }