import { MDEEditable} from "./SimpleMDE";
import React, { useState } from "react";
import "easymde/dist/easymde.min.css";
import {getDocEditorPrompt, initialDocEditPrompt} from "./prompts";
import {getAPIStreamSourceChat} from "./api.js"


const DocEditorPage = () => {
  const [inputText, setInputText] = useState("What's the go to market strategy? \n Do you have 1-year revenue projections?");
  const [outputText, setOutputText] = useState(initialDocEditPrompt);
  const [isLoading, setIsLoading] = useState(false);
  
  const makeApiRequest = async () => {
    let prompt = getDocEditorPrompt(outputText, inputText)
    let source = getAPIStreamSourceChat(prompt)    
    source.addEventListener('message', function(e) {
        console.log(e.data);
        if (e.data == "[DONE]") {
            setIsLoading(false)
            return
        }
        const json = JSON.parse(e.data);
        
        const completion = json.choices[0].text;
        setOutputText(prevData => prevData + completion)
    });
    source.stream();

    setIsLoading(true)
    setOutputText('')
  };

const buttonText = isLoading ? 'Working...' : 'Request Edits';
return (
  <div className="App">
        <header className="App-header">
        <div className="title">Doc Editor AI</div>
        <MDEEditable 
            value={outputText}
            onChange={e => setOutputText(e)}
        />
        <div className="flex-row-container">
            <input 
              placeholder='Instructions or questions. Eg: What is the go to market strategy?' 
              autoFocus={true} 
              type='text' 
              onChange={e => setInputText(e.target.value)} 
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  makeApiRequest()
                }
              }} 
              value={inputText}></input>
            <button 
              className='full-height-button'
              disabled={isLoading} 
              onClick={makeApiRequest} >
              {buttonText}
            </button>
        </div>
    </header>
  </div>
)
}
export default DocEditorPage