// Firebase DB Collections
// queries ~ [{outputText, queryText, tables:[{name, schema}], timestamp}]
// customers ~ {email, stripeId, stripeLink}

import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/functions'
import { onAuthStateChanged } from 'firebase/auth'


import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

const SQLStatsPage = () => {
  const [users, setUsers] = useState([]);
  const [queries, setQueries] = useState([]);
  const [queriesByDay, setQueriesByDay] = useState(null);
  const [user, setUser] = useState(null);

  const fetchUsers = async () => {
    let db = firebase.firestore();

    const usersRef = db.collection("customers");
    const usersData = await usersRef.get();
    setUsers(usersData.docs.map((doc) => doc.data()));
  };

  const getQueriesCountByDay = async () => {
    let db = firebase.firestore();
    const queriesSnapshot = await db
      .collection('queries')
      .where('timestamp', '>=', new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000))
      .orderBy('timestamp')
      .get();

    let currentDay = '';
    const countByDay = {};
    const uniqueQueriesByDay = {};
    const uniqueUsersByDay = {};

    queriesSnapshot.docs.forEach((doc) => {
      const timestamp = doc.data().timestamp.toDate();
      const dayString = `${timestamp.getFullYear()}-${timestamp.getMonth() + 1}-${timestamp.getDate()}`;
      const query = doc.data().queryText;
      const userId = doc.data().userId ? doc.data().userId : 'anonymous';

      if (currentDay !== dayString) {
        currentDay = dayString;
        countByDay[currentDay] = 1;
        uniqueQueriesByDay[currentDay] = new Set([query]);
        uniqueUsersByDay[currentDay] = new Set([userId]);
      } else {
        countByDay[currentDay]++;
        uniqueQueriesByDay[currentDay].add(query);
        uniqueUsersByDay[currentDay].add(userId);
      }
    });

    // Convert sets to counts of unique queries
    const countsByDay = {};
    for (const day in uniqueQueriesByDay) {
      countsByDay[day] = {unique: uniqueQueriesByDay[day].size, total: countByDay[day], uniqueUsers: uniqueUsersByDay[day].size};
    }
    return countsByDay;
  };

  useEffect(() => {
    onAuthStateChanged(firebase.auth(), (user) => {
      //console.log(user)
      if (user) {
        setUser({ name: user.displayName, email: user.email, id: user.uid })
      } else {
        setUser(null)
      }
    })
  }, []);

  useEffect(() => {
    // async fetch
    const doFetch = async () => {
      fetchUsers();
      const queriesByDay = await getQueriesCountByDay();
      setQueriesByDay(queriesByDay);
    }
    if (user) {
      doFetch();
    }
  }, [user]);

  const renderQueriesByDay = () => {
    console.log(queriesByDay)
    console.log(Object.entries(queriesByDay))
    const rows = Object.entries(queriesByDay).map(([day, counts]) => (
      <tr key={day}>
        <td>{day}</td>
        <td>{counts.total}</td>
        <td>{counts.unique}</td>
        <td>{counts.uniqueUsers}</td>
      </tr>
    ));
    return (
      <table>
        <thead>
          <tr>
            <th>Day</th>
            <th>Total Queries</th>
            <th>Distinct Queries</th>
            <th>Unique Users</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>
    );
  };

  return (
    <div>
      <h1>SQL Stats</h1>
      <h2>Queries by Day</h2>
      {queriesByDay && renderQueriesByDay()}
      <h2>Total Users</h2>
      {users.length}
    </div>
  );
};

export default SQLStatsPage;