import { useEffect, useRef, useState } from 'react';
import "firebase/compat/auth";
import firebase from 'firebase/compat/app';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';

const StyledFirebaseAuth = () => {
    const [userSignedIn, setUserSignedIn] = useState(false);
    const elementRef = useRef(null);

    useEffect(() => {
        // Get or Create a firebaseUI instance.
        const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
        ui.start('.firebase-auth-container', {
            signInFlow:'popup',
            signInSuccessUrl: window.location.origin + '/',
            signInOptions: [
                {
                    provider: firebase.auth.EmailAuthProvider.PROVIDER_ID
                }, 
                {
                    provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID
                }
            ],
        });
    }, []);
};

export default StyledFirebaseAuth;