import React, { useState, useEffect, useRef } from "react";
import "easymde/dist/easymde.min.css";
import { MDEEditable, MDEPreviewOnly } from "./SimpleMDE";
import { getLinterPrompt, getDocumentTypePrompt } from "./prompts";
import { getAPIStreamSourceChat, getAPICompletion } from "./api.js"
import CssBaseline from '@mui/material/CssBaseline';
import { List, ListItem, ListItemButton, ListItemText, Button, AppBar, Toolbar, Typography, Grid, Drawer, Fab, Select, MenuItem, FormControl, InputLabel, Box, Divider, Tabs, Tab, Accordion, AccordionDetails, AccordionSummary } from '@mui/material'

import TabPanel from "./components/TabPanel"

import { defaultLinterRulesDict, defaultLinterRulesText, defaultLinterDocumentText } from './default_text'

const LinterPage = () => {
  const [documentText, setDocumentText] = useState(defaultLinterDocumentText)
  const [lintOutputText, setLintOutputText] = useState('')
  const [hiddenLintOutputText, setHiddenLintOutputText] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [lintRulesDict, setLintRulesDict] = useState(defaultLinterRulesDict)
  const [selectedRuleName, setSelectedRuleName] = useState(Object.keys(lintRulesDict)[0]);
  const [buffer, setBuffer] = useState(['']);

  const hiddenLintOutputTextRef = useRef(hiddenLintOutputText)
  hiddenLintOutputTextRef.current = hiddenLintOutputText

  const isLoadingRef = useRef()
  isLoadingRef.current = isLoading
  
  let simpleMdeInstanceRef = useRef();

  const handleTabChange = (event, newValue) => {
    setSelectedTabIndex(newValue);
    setTimeout(function() { simpleMdeInstanceRef.current.codemirror.refresh(); // Yuck. Hack to get simplemde to show text on unloaded page
      setLintRulesDict((prevValue) => {
        let copy = {...prevValue}
        return prevValue
      })
    }, 0);
  };

  const handleRuleClick = (name) => {
    setSelectedRuleName(name)
  }

  const handleGetLintClick = async () => {
    setIsLoading(true)
    setLintOutputText('') // add some new lines

    const prompt = getDocumentTypePrompt(documentText, Object.keys(lintRulesDict))
    console.log(prompt)
    const type = await getAPICompletion(prompt, {temperature:0})
    if (type in lintRulesDict) {
      setLintOutputText('**Document Type: '+type+'**\n')
      makeApiRequest(lintRulesDict[type]['rules'])
      
      /*let intervalID = setInterval(() => {
        console.log(`Interval running. Buffer len: ${hiddenLintOutputTextRef.current.length}`)
        let hiddenWords = hiddenLintOutputTextRef.current.split(' ')
        console.log(isLoadingRef.current)
        console.log(hiddenWords.length)
        setLintOutputText(prevValue => prevValue + ' ' + hiddenWords[0])
        setHiddenLintOutputText(hiddenWords.slice(1).join(' '))
        if (isLoadingRef.current == false && hiddenWords.length == 1) {
          clearInterval(intervalID)
          console.log('clearing interval')
          return
        }        
      },150)*/
    } else {
      setLintOutputText('Sorry we couldn\'t understand your document or there are no rules for this type yet.')
      setIsLoading(false)
    }
  }

  const makeApiRequest = async (selectedRules) => {
    let prompt = getLinterPrompt(selectedRules, documentText);
    console.log(prompt)
    //debugger
    let source = getAPIStreamSourceChat(prompt, {temperature:0})
    source.addEventListener('message', function (e) {
      // ONLY SET CALLS INSIDE LISTENERS. NO FUNCTION CALLS DIRECTLY, NO REFERENCING OUTSIDE VARS PLEASE
      if (e.data == "[DONE]") {
        setIsLoading(false)
        return
      }
      const json = JSON.parse(e.data);
      const delta = json.choices[0].delta;
      const completion = delta.content ? delta.content : ''

      //setHiddenLintOutputText(prevData => (prevData + completion).trimStart())
      setLintOutputText(prevData => (prevData + completion).trimStart())
      console.log(hiddenLintOutputTextRef.current)
    });
    source.stream();
    setIsLoading(true)
  };

  return (
    <Box height="100vh" className={'flexContainer flexItem flexColumn'}>
      <Tabs onChange={handleTabChange} value={selectedTabIndex} textColor='secondary' indicatorColor='secondary'>
        <Tab label='Doc Linter' />
        <Tab label='Edit Lint Rules' />
      </Tabs>
      <TabPanel className={'flexContainer flexItem flexColumn'} value={selectedTabIndex} index={0}>
        <Box className={'flexContainer flexItem flexRow'} style={{gap:'15px', margin:'15px'}} >
          <div className={'flexContainer flexColumn flexItem'} style={{ flex:1, maxWidth:'60%' }}>
            <div className={'flexContainer flexColumn flexItem'}>
              <Typography variant='h6'>Document</Typography>
              <MDEEditable value={documentText} onChange={e => setDocumentText(e)} />
              </div>
          </div>
          <div className={'flexItem flexContainer flexColumn'} style={{flex:.7}}>
              <Typography variant='h6'>Lint Score</Typography>
              <MDEPreviewOnly value={lintOutputText} />
              <div className={'flex0Item'} style={{ alignSelf: 'flex-end' }}>
                <Button variant='contained' onClick={handleGetLintClick}>Get Lint Score</Button>
            </div>
            </div>
        </Box>
      </TabPanel>
      <TabPanel className={'flexContainer flexItem flexColumn'} value={selectedTabIndex} index={1}>
        <Box className={'flexContainer flexRow flexItem'}>
          <div className={'flexContainer flexRow flexItem'}>
            <div className={'flexContainer flexColumn flexItem sidebar'}>
              <List>
                {Object.keys(lintRulesDict).map((ruleName) => (
                  <ListItemButton key={ruleName} selected={ruleName==selectedRuleName} onClick={() => handleRuleClick(ruleName)}>{ruleName}</ListItemButton>
                ))}
              </List>
            </div>
            <div className={'flexItem'}>
            <MDEEditable value={lintRulesDict[selectedRuleName]['rules']} onChange={(e) => {
                const items = {...lintRulesDict}
                items[selectedRuleName] = {rules:e}
                setLintRulesDict(items)
              }}
              ref={simpleMdeInstanceRef}
               />
            </div>  
          </div>
        </Box>
      </TabPanel>

    </Box>
  )
}
export { LinterPage }