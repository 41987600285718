import { MDEEditable, MDEPreviewOnly } from "./SimpleMDE";
import React, { useState, useEffect } from "react";
import "easymde/dist/easymde.min.css";
import { getDocFeedbackPrompt, initialDocEditPrompt } from "./prompts";
import { getAPIStreamSourceChat } from "./api.js"
import { HelmetProvider, Helmet } from 'react-helmet-async';
import {SSE} from "./sse.js"



const DocFeedbackPage = () => {
  const [inputText, setInputText] = useState(initialDocEditPrompt);
  const [outputText, setOutputText] = useState('');
  const [customAuthor, setCustomAuthor] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.querySelector('html').setAttribute('data-theme', 'mytheme');
    console.log('setting theme to cupcake')
  }, [])

  const getTestStreamSource = (inputText, feedbackAuthor) => {
    let source = new SSE("http://localhost:5000/getCardCompletion", {
        method: "POST",
        headers: {
        Authorization: `Bearer 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`,
        "Content-Type": "application/json"
        },
        payload: JSON.stringify({
        "document": inputText,
        "feedback_author":feedbackAuthor
      })
    })
      return source
  }

  const makeApiRequest = async (feedbackAuthor) => {
    let prompt = getDocFeedbackPrompt(inputText, feedbackAuthor.description)
    //let source = getTestStreamSource(inputText,feedbackAuthor)
    let source = getAPIStreamSourceChat(prompt,{temperature:.8})
    setOutputText(prevData => prevData + '\n\n**' + feedbackAuthor.title + ' feedback:**\n') // add some new lines
    source.addEventListener('message', function (e) {
      console.log(e.data);
      const json = JSON.parse(e.data);
      const completion = json.choices[0].text;
      setOutputText(prevData => prevData + completion)
      const finish_reason = json.choices[0].finish_reason
      console.log(finish_reason)
      if (finish_reason != null) {
        setIsLoading(false)
      }
    });
    source.stream();

    setIsLoading(true)
  };

  const personas = [
    {title:'CEO', description:'Your company\'s CEO who is very brief in text. Wants big swings and 110% from employees'}, 
    {title:'CFO', description:'Your company\'s CFO who wants facts and figures'},
    {title:'Customers', description:'Customers of the document\'s product or service. They may be delighted or unimpressed'},
    {title:'Twitter at large', description:'Twitter at large, who is full of critics and meme lords'},
    {title:'The US President', description:'The US President who wants what is good for the American people'},
    {title:'Your Grandma', description:'Your Grandma who always calls you pumpkin and wants the best for you'},
    {title:'Product Manager', description:'Product Manager nitpicking every part of your PRD. They are especially brutal if you don\'t include KPIs, user stories, multi-language support'},
    {title:'Product Designer', description:'Product Designer'},
    {title:'Engineer', description:'Your company\'s CFO who wants facts and figures'},
    {title:'Venture Capitalist', description:'VC Investors who almost always ask "did Sequoia invest?'},
    {title:'Elon Musk', description:'Elon Musk high on weed'},]
      
  return (
    <div className="flex flex-col p-4">
      <div className="flex flex-col">
        <div className="text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-tr from-purple-600 to-pink-600">PRD Feedback AI</div>
        <div className="flex flex-row flex-wrap w-full">
        <div className="flex flex-col grow flex-wrap gap-8 card p-4 m-4 max-w-screen-md">
          <div className="flex max-w-screen-md md:max-w-none">
            <MDEEditable
              value={inputText}
              onChange={e => setInputText(e)}
              maxHeight={'300px'}
              shouldAutoFocus={false}
            />
          </div>

          <div className="flex flex-col">
            <span>Get feedback from</span>
            <div className="flex flex-wrap flex-row max-w-sm md:max-w-none gap-1 align-items-start">

              {personas.map((persona) => (
                <button
                  className="btn w-fit btn-primary"
                  disabled={isLoading}
                  onClick={() => makeApiRequest(persona)} >
                  {persona.title}
                </button>
              ))}
            </div>
            <div className='flex flex-col'>
                <input
                  className="px-4 py-3 rounded-md my-5 border-black border"
                  placeholder='Add your own (eg: Thomas Edison)'
                  autoFocus={true}
                  type='text'
                  onChange={e => setCustomAuthor(e.target.value)}
                  onKeyPress={event => {
                    if (event.key === 'Enter') {
                      makeApiRequest(customAuthor)
                    }
                  }}
                  value={customAuthor}></input>
                <button
                  className='btn btn-primary'
                  disabled={isLoading || customAuthor == ''}
                  onClick={() => makeApiRequest(customAuthor)} >
                  Request Feedback
                </button>
              </div>
            </div>

        </div>
        <div className="flex grow flex-col card p-4 m-4 md:min-width-full max-w-xl">
          <span className='textxl font-bold'>The Feedback</span>
          <MDEPreviewOnly
            value={outputText}
            onChange={e => setOutputText(e)}
          />

          <div className="flex flex-row-reverse">
            <button
              className="btn my-4"
              disabled={isLoading}
              onClick={() => setOutputText('')} >
              Clear Feedback
            </button>
          </div>
        </div>
        </div>

      </div>
    </div>
  )
}
export default DocFeedbackPage