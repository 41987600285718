import {TextField, Stack, Accordion, AccordionSummary, AccordionDetails, Button} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useContext } from 'react';
import { InputContext } from '../SQLPage';

const TableInput = () => {
    const { tables, setTables } = useContext(InputContext);

    const handleRemoveTable = (index) => {
        console.log(`splicing at index ${index}`)
        setTables(prevValue => {
            let newValue = [...prevValue]
            newValue.splice(index, 1)
            return newValue
        })
    }

    const handleAddTable = () => {
        setTables(prevValue => [...prevValue,{name:'',schema:''}])
    }

    return (
    <Stack sx={{m:0, p:0}}>
        {tables.map((table, index) => (
        <Accordion disableGutters={true} key={index} defaultExpanded={index==0 || table.name==''} >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{fontSize:'.9em', fontWeight:'600'}} >{table.name ? table.name : 'New Table'}</AccordionSummary>
            <AccordionDetails>
            <Stack spacing={2}>
            <Stack direction='row' sx={{justifyContent:'space-between'}}>
            <TextField
                sx = {{backgroundColor:'white'}}
                label='Table Name'
                placeholder={'Your table\'s name'} 
                autoFocus={true} 
                type='text' 
                onChange={(e) => {
                setTables(prevValue => {
                    let newValue = [...prevValue]
                    newValue[index].name = e.target.value
                    return newValue
                })
                }}
                onKeyPress={event => {}}
                value={table.name}>
            </TextField>
            <Button variant='text' color='error' onClick={(e) => handleRemoveTable(index)}>Remove Table</Button>
            </Stack>
            <TextField
                sx = {{backgroundColor:'white', width:'100%'}}
                multiline
                maxRows={10}
                label='Table Schema'
                placeholder='Your table schema'
                type='text' 
                onChange={(e) => {
                setTables(prevValue => {
                    let newValue = [...prevValue]
                    newValue[index].schema = e.target.value
                    return newValue
                })
                }}
                onKeyPress={event => {}}
                value={table.schema}>
            </TextField>
      </Stack>
      </AccordionDetails>
    </Accordion>
    )
    )}
    <Stack direction='row-reverse'>
        <Button variant='contained' onClick={(e) => handleAddTable()} sx={{marginTop:1}}>Add New Table</Button>
    </Stack>
    </Stack>
    )
}

export default TableInput