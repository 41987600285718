function getRecipePrompt(input) {

    const prePrompt = 'Input: Japanese Shishito Peppers \n\
    Output: \n\
    ## Recipe for Japanese Shishito Peppers \n\
    \n\
    **Ingredients** \n\
    * 1 pound shishito peppers \n\
    * 1 tablespoon olive oil \n\
    * 1/4 teaspoon salt \n\
    * 1/4 teaspoon black pepper \n\
    \n\
    **Instructions** \n\
    1. Preheat oven to 425 degrees F. \n\
    2. In a large bowl, toss shishito peppers with olive oil, salt and black pepper. \n\
    3. Spread peppers in a single layer on a baking sheet. \n\
    4. Roast for about 15 minutes, or until peppers are blistered and slightly charred. \n\
    5. Serve immediately. \n\
    \n\
    --- \n\
    Input:  Instant Pot Mashed Potatoes \n\
    Output: \n\
    ## Recipe for Instant Pot Mashed Potatoes \n\
    **Ingredients** \n\
    * 1 lb. potatoes \n\
    * 1 cup water \n\
    * 1 tsp. salt \n\
    * 1/4 cup milk \n\
    * 2 tbsp. butter \n\
    \n\
    **Instructions** \n\
    1. Peel and chop the potatoes into 1-inch pieces. \n\
    2. Add the potatoes, water and salt to the Instant Pot. \n\
    3. Close the lid and set the valve to sealing. \n\
    4. Cook on manual high pressure for 10 minutes. \n\
    5. Once the cook time is up, let the pressure release naturally for 5 minutes, then quick release any remaining pressure. \n\
    6. Add the milk and butter and mash the potatoes until smooth. \n\
    \n\
    --- \n\
    Input: What\'s the square root of 2? \n\
    Output: That\'s not a recipe, try something like "Seared Tuna" or "Tea Leaf Salad" \n\
    \n\
    --- \n\
    Input: How fast can a horse run? \n\
    \n\
    Output: That\'s not a recipe, try something like "Seared Tuna" or "Tea Leaf Salad" \n\
    --- \n\
    Input: '
    return prePrompt + input + '\nOutput:'
}

function getDocEditorPrompt(input, instructions) {
    const prePrompt = '\
    Rewrite without increasing length tool\n\
Text Input:\n\
# Mugsy Business plan\n\
Mugsy is the best travel mug for keeping your coffee hot all day long. No matter where you take it or how long you\'re gone, your coffee will still be piping hot when you\'re ready for a refill.\n\
\n\
Here\'s how it works: Mugsy uses a double-walled insulation design to keep your coffee hot (or cold) for hours on end. It also has a vacuum-sealed lid that prevents any heat from escaping.\n\
\n\
So whether you\'re commuting to work, running errands, or spending a day at the beach, Mugsy will make sure your coffee is always hot and fresh.\n\
\n\
## Why Mugsy is the best travel mug:\n\
1. Double-walled insulation keeps coffee hot for hours\n\
2. Vacuum-sealed lid prevents heat from escaping\n\
3. Durable stainless steel construction\n\
4. Keeps coffee hot all day long\n\
\n\
## What is the target market for Mugsy?\n\
Mugsy\'s target market is anyone that wants to drink hot coffee all day long. That includes:\n\
\n\
Commuters who need their coffee to stay hot on the way to work\n\
Families who like to take their coffee with them while running errands\n\
Coffee lovers who don\'t have time to stop for refills\n\
People who work outside and need to keep their coffee hot\n\
Anyone who enjoys drinking coffee\n\
\n\
## What is the price point of Mugsy?\n\
Mugsy will cost $24.99.\n\
\n\
Instructions Input:\n\
What is the go to market strategy for Mugsy?\n\
You don\'t need as much information about the product itself\n\
Do you have revenue projections? \n\
\n\
Output:\n\
# Mugsy Business plan\n\
Mugsy is the best travel mug for keeping your coffee hot all day long. No matter where you take it or how long you\'re gone, your coffee will still be piping hot when you\'re ready for a refill.\n\
\n\
Here\\\'s how it works: Mugsy uses a double-walled insulation design to keep your coffee hot (or cold) for hours on end. It also has a vacuum-sealed lid that prevents any heat from escaping. So whether you\'re commuting to work, running errands, or spending a day at the beach, Mugsy will make sure your coffee is always hot and fresh.\n\
\n\
## What is the target market for Mugsy?\n\
Mugsy\'s target market is anyone that wants to drink hot coffee all day long. That includes:\n\
\n\
Commuters who need their coffee to stay hot on the way to work\n\
Families who like to take their coffee with them while running errands\n\
Coffee lovers who don\'t have time to stop for refills\n\
People who work outside and need to keep their coffee hot\n\
Anyone who enjoys drinking coffee\n\
\n\
## Go to Market Plan\n\
Mugsy will cost $24.99 and will launch online and in stores. We\'ll generate demand using online pay-per-click and display ads, and work with coffee influencers to generate buzz.\n\
\n\
## Revenue projections\n\
Based on early estimates, we expect 40k-50k units for ~$1M of year-1 revenue with 20%-30% growth in years 2-5\n\
\n\
-----\n\
'
    return prePrompt + 'Text Input:\n' + input + '\nInstructions Input:\n' + instructions + '\n\Output:\n'
}

const initialDocEditPrompt = '\
## Mugsy Business plan\n\
Mugsy is the best travel mug for keeping your coffee hot all day long. No matter where you take it or how long you\'re gone, your coffee will still be piping hot when you\'re ready for a refill.\n\
\n\
Here\'s how it works: Mugsy uses a double-walled insulation design to keep your coffee hot (or cold) for hours on end. It also has a vacuum-sealed lid that prevents any heat from escaping. So whether you\'re commuting to work, running errands, or spending a day at the beach, Mugsy will make sure your coffee is always hot and fresh.\n\
\n\
### Why Mugsy is the best travel mug:\n\
1. Double-walled insulation keeps coffee hot for hours\n\
2. Vacuum-sealed lid prevents heat from escaping\n\
3. Durable stainless steel construction\n\
4. Keeps coffee hot all day long\n\
\n\
### What is the target market for Mugsy?\n\
Mugsy\'s target market is anyone that wants to drink hot coffee all day long. That includes:\n\
\n\
Commuters who need their coffee to stay hot on the way to work\n\
Families who like to take their coffee with them while running errands\n\
Coffee lovers who don\'t have time to stop for refills\n\
People who work outside and need to keep their coffee hot\n\
Anyone who enjoys drinking coffee\n\
'

// This can be written to WAY decrease tokens by listing the authors we want instead of one at a time
function getDocFeedbackPrompt(input, feedbackAuthor) {
    return `\
Feedback requester: Given an input document and the feedback author, generate feedback to make the document better.\n\
Input:\n\
## Mugsy Business plan\n\
Mugsy is the best travel mug for keeping your coffee hot all day long. No matter where you take it or how long you're gone, your coffee will still be piping hot when you're ready for a refill.\n\
\n\
Here's how it works: Mugsy uses a double-walled insulation design to keep your coffee hot (or cold) for hours on end. It also has a vacuum-sealed lid that prevents any heat from escaping. So whether you're commuting to work, running errands, or spending a day at the beach, Mugsy will make sure your coffee is always hot and fresh.\n\
\n\
### Why Mugsy is the best travel mug:\n\
1. Double-walled insulation keeps coffee hot for hours\n\
2. Vacuum-sealed lid prevents heat from escaping\n\
3. Durable stainless steel construction\n\
4. Keeps coffee hot all day long\n\
\n\
### What is the target market for Mugsy?\n\
Mugsy's target market is anyone that wants to drink hot coffee all day long. That includes:\n\
\n\
Commuters who need their coffee to stay hot on the way to work\n\
Families who like to take their coffee with them while running errands\n\
Coffee lovers who don't have time to stop for refills\n\
People who work outside and need to keep their coffee hot\n\
Anyone who enjoys drinking coffee\n\
\n\
Feedback Author: VC Investor\n\
Output: \n\
What are your revenue projections?\n\
How many customers do you have so far?\n\
What valuation are you raising at?\n\
\n\
Feedback Author: Consultant\n\
Output:\n\
What's the go to market strategy?\n\
What's the competitive landscape like? Don't they already have this?\n\
You need to focus on one segment versus boiling the ocean with 5 segments\n\
\n\
Feedback Author: Customer\n\
Output:\n\
Why is this better than a normal mug I could buy on Amazon?\n\
Where do I buy this?\n\
I don't think I'd bring a hot coffee to the beach... wtf\n\
\n\
Feedback Author: Non-coffee drinker\n\
Output: I don't have any feedback here, I don't drink coffee\n\
\n\
Feedback Author: Grammar coach\n\
Output: This looks grammatically correct and has reasonable sentence flow\n\
---\n\
Input Text:\n\
Pigs are intelligent, social animals that make great pets. They are easily trained, can be litter box trained, and are very affectionate. Pigs are also very clean animals and enjoy spending time grooming themselves.\n\
\n\
Pigs make great walking or running partners and love to play fetch. They are also very good swimmers. Pigs are curious animals and love to explore their surroundings.\n\
\n\
Pigs are very social animals and enjoy the company of other pigs and humans. They are gentle animals and make great pets for children. Pigs are also very low maintenance animals and are easy to care for. In fact, pigs are popular pets in Portugal and France!\n\
\n\
Feedback Author: Cat Owner\n\
Output:\n\
I don't think pigs would be good pets for children. They are way too big and they would knock over everything.\n\
\n\
Feedback Author: Persuasive writing editor\n\
Output:\n\
You need to focus on why pigs make great pets, rather than listing their features. What are the benefits of owning a pig as a pet? How will a pig enrich the life of its owner?\n\
\n\
Feedback Author: Grammar coach\n\
Output:\n\
This looks grammatically correct and has reasonable sentence flow.\n\
\n\
Feedback Author: Persuasive writing editor\n\
Output:\n\
You need to focus on why pigs make great pets, rather than listing their features. What are the benefits of owning a pig as a pet? How will a pig enrich the life of its owner?\n\
\n\
Feedback Author: CEO\n\ 
Output:\n\
This doesn't seem like a business proposal, I don't have much feedback here\n\
\n\
Feedback Author: General twitter reception\n\
Output:\n\
I would never own a pig, they're so dirty\n\
Pets are a huge responsibility, you should think about that before you get one\n\
I love pigs, they're so cute and intelligent!\n\
---\n\
Input:\n\
${input}\n\
Feedback Author: ${feedbackAuthor}\n\
Output:\n`;
}

const SQLPretrainExamples = "\n\
SQL Query Generator\n\
SQL Type: Postgres SQL\n\
\n\
Table structure: \n\
\n\
Table #0 Name: admissions\n\
Table Schema:\n\
patient_id	INT \n\
admission_date	DATE\n\
discharge_date	DATE\n\
diagnosis	VARCHAR(50)\n\
attending_doctor_id	INT\n\
\n\
Table #1 Name: patients\n\
Table Schema:\n\
patient_id	INTEGER \n\
first_name	VARCHAR(30)\n\
last_name	VARCHAR(30)\n\
gender	CHAR(1)\n\
birth_date	DATE\n\
city	VARCHAR(30)\n\
province_id	CHAR(2)\n\
allergies	VARCHAR(80)\n\
height	DECIMAL(3, 0)\n\
weight	DECIMAL(4, 0)\n\
\n\
Table #2 Name: doctors\n\
Table Schema:\n\
doctor_id	INTEGER\n\
first_name	VARCHAR(30)\n\
last_name	VARCHAR(30)\n\
specialty	VARCHAR(25)\n\
\n\
Desired query in English: Get the top 5 doctors who have treated the most patients with bee allergies\n\
--Semi-structured interpretation: Given all the doctors who have been the attending doctor for patients with bee allergies, return the 5 doctors with the most admissions\n\
--Output SQL Query:\n\n\
SELECT DISTINCT d.doctor_id, d.first_name, d.last_name, count(*) as num_patients\n\
FROM patients p\n\
JOIN admissions a ON p.patient_id = a.patient_id\n\
JOIN doctors d ON a.attending_doctor_id = d.doctor_id\n\
WHERE allergies LIKE '%bee%'\n\
GROUP BY d.doctor_id, d.first_name, d.last_name\n\
ORDER BY num_patients DESC\n\
LIMIT 5\n\
\n\
--Example rows\n\
--Doctor ID | First Name | Last Name | Number of Patients\n\
--------------------------------------------------------------\n\
--     9102 |     Allison |   Rogers   |             17\n\
--     5561 |        Jack |     Bauer  |             15\n\
--     3344 |       Chloe |    O'Brian |             14\n\
--\n\
\n\
\n\
Desired query in English: Plot visits by specialty weekly\n\
--Semi-structured interpretation: Return x and y axis to be used in a plot. X: Calendar date by week; Y: patient admissions grouped by the specialties of the attending_doctor\n\
--Output SQL Query:\n\n\
SELECT \n\
date_trunc('week', admission_date) as admission_week,\n\
COUNT(*) as weekly_admissions,\n\
d.specialty\n\
FROM admissions a\n\
JOIN doctors d\n\
ON d.doctor_id = a.attending_doctor_id\n\
GROUP BY specialty, admission_week\n\
ORDER BY admission_week ASC;\n\
\n\
--Example rows\n\
--admission_week | weekly_admissions | specialty\n\
-----------------|-------------------|------------\n\
--2018-01-01     | 10                | Neurology\n\
--2018-01-01     | 5                 | Oncology\n\
--2018-01-01     | 7                 | Pediatrics\n\
--\n\
\n\
Desired query in English: Show weekly admissions and rolling 4-week average admissions over time\n\
--Semi-structured interpretation: Return x and y axis to be used in a plot. X: Calendar date by week; Y: patient admissions, 4-week rolling average of admissions\n\
--Output SQL Query:\n\n\
SELECT \n\
date_trunc('week', admission_date) as admission_week,\n\
COUNT(*) as weekly_admissions,\n\
AVG(COUNT(*)) OVER (ORDER BY date_trunc('week', admission_date) ROWS BETWEEN 3 PRECEDING AND CURRENT ROW) as rolling_avg\n\
FROM admissions\n\
GROUP BY admission_week\n\
ORDER BY admission_week ASC;\n\
\n\
--Example rows\n\
--admission_week | weekly_admissions | rolling_avg\n\
-----------------|-------------------|------------\n\
--2018-01-01     | 10                | 10\n\
--2018-01-08     | 12                | 11\n\
--2018-01-15     | 15                | 13\n\
--2018-01-22     | 17                | 15\n\
\n\
\n\
Desired query in English: Stacked line chart of % of patients admitted who left within 1 week\n\
--Semi-structured interpretation: Return x and y axis to be used in a plot. X: Calendar date by week; Y: % of patients admitted who left the hospital within 1 week\n\
--Output SQL Query:\n\n\
SELECT \n\
date_trunc('week', admission_date) as admission_week,\n\
SUM(CASE WHEN discharge_date <= admission_date + interval '1 week' THEN 1 ELSE 0 END) / COUNT(*)::decimal as percent_within_1_week\n\
FROM admissions\n\
GROUP BY admission_week\n\
ORDER BY admission_week ASC;\n\
\n\
--Example rows\n\
--admission_week | percent_within_1_week\n\
-----------------|---------------------\n\
--2018-01-01     | 0.8\n\
--2018-01-08     | 0.9\n\
--2018-01-15     | 0.85\n\
--2018-01-22     | 0.8\n\
\n\n\
Desired query in English: Show diagnoses that have been growing the most month over month in recent months?\n\
--Semi-structured interpretation: Return x and y to be used in a plot. X: Calendar date by month for the past 12 months. Y: Total admissions and % change in admissions for each diagnosis\n\
--Output SQL Query:\n\
SELECT \n\
date_trunc('month', admission_date) as admission_month,\n\
diagnosis,\n\
COUNT(*) as num_admissions,\n\
(COUNT(*) - LAG(COUNT(*)) OVER (ORDER BY date_trunc('month', admission_date))) / LAG(COUNT(*)::decimal) OVER (ORDER BY date_trunc('month', admission_date)) as percent_change\n\
FROM admissions\n\
GROUP BY admission_month, diagnosis\n\
ORDER BY admission_month ASC\n\\n\
\n\
--Example rows\n\
--admission_month | diagnosis       | num_admissions | percent_change\n\
------------------|-----------------|----------------|---------------\n\
--2018-01-01     | Bee Allergy     | 10             | 0\n\
--2018-01-01     | Cold            | 5              | 0\n\
--2018-01-01     | Flu             | 7              | 0\n\
\n\
Desired query in English: Show incident rates of the top 5 ailments this month going back 5 years\n\
--Semi-structured interpretation: Return x and y to be used in a plot. X: Calendar date by month for the past 5 years. Y: Given the top diagnoses by admissions in the past month, return the monthly number of cases\n\
--Output SQL Query:\n\
SELECT \n\
date_trunc('month', admission_date) as admission_month,\n\
diagnosis,\n\
COUNT(*) as num_admissions\n\
FROM admissions\n\
WHERE diagnosis IN (SELECT diagnosis\n\
                    FROM admissions\n\
                    WHERE admission_date > (NOW() - INTERVAL '1 month')\n\
                    GROUP BY diagnosis\n\
                    ORDER BY COUNT(*) DESC\n\
                    LIMIT 5)\n\
GROUP BY admission_month, diagnosis\n\
ORDER BY admission_month ASC;\n\
\n\
--Example rows\n\
--admission_month | diagnosis       | num_admissions\n\
------------------|-----------------|---------------\n\
--2018-01-01     | Bee Allergy     | 10\n\
--2018-01-01     | Cold            | 5\n\
--2018-01-01     | Flu             | 7\n\
\n\
Desired query in English: average length of stay rounded to one decimal point by attending\n\
--Semi-structured interpretation: Return x and y to be used in a plot. X: Attending doctor; Y: Average length of stay (rounded to one decimal point)\n\
--Output SQL Query:\n\
\n\
SELECT \n\
d.doctor_id,\n\
d.first_name,\n\
d.last_name,\n\
ROUND(AVG(EXTRACT(DAY from AGE(discharge_date, admission_date))), 1) AS avg_length_of_stay\n\
FROM admissions a \n\
JOIN doctors d\n\
ON d.doctor_id = a.attending_doctor_id\n\
GROUP BY d.doctor_id, d.first_name, d.last_name;\n\
\n\
--Example rows\n\
--Doctor ID | First Name | Last Name | avg_length_of_stay\n\
--------------------------------------------------------------\n\
--     9102 |     Allison |   Rogers   |             7.2\n\
--     5561 |        Jack |     Bauer  |             8.0\n\
--     3344 |       Chloe |    O'Brian |             6.3\n\
\n\n"


function getSQLGeneratorPrompt(sqlType, tables, queryInput, usePretrain=true) {
    let pretrainExamples = usePretrain ? SQLPretrainExamples : ''
    let tableStructureString = tables.map((table, i) => `Table #${i} Name: ${table.name}\nTable Schema:\n${table.schema}\n\n`).join('')
    //let semiStructured = semiStructuredText ? '--Semi-structured interpretation: '+semiStructuredText : ''
    return `\
SQL Query Generator\n\
Writes SQL queries in the format\n\
--Desired Query in English: $(user's input)\n\
--Semi-structured Interpretation: $(English representation of how the query will be formatted)\n\
--Output SQL query: $(the formatted SQL query)\n\
--Example rows: $(3 example rows of what this query might output)\n\n\

If the request can't be answered with the data provided, or doesn't make sense, kindly tell the user to try again.

SQL Type: ${sqlType}\n\n\
Table structure: \n\n\
${tableStructureString}\
${pretrainExamples}\n\
Desired query in English: ${queryInput}\n`
}

function getPSCPrompt(companyPillarsText, roleExpectationsText, accomplishmentsText, reviewSoFarText) {
    return `Performance self review writer: This tool writes a confident and positive employee performance review\n\n\
    Company Pillars: ${companyPillarsText}\n\
    Role Expectations: ${roleExpectationsText}\n\
    Accomplishments: ${accomplishmentsText}\n\
    \n\
    Write a comprehensive performance review (5-8 paragraphs) for Andrea, focusing on her accomplishments and areas for improvement (if any). Use new paragraphs for each project or area of expertise:\n${reviewSoFarText}`
}

function getExpandPSCPrompt(companyPillarsText, roleExpectationsText, accomplishmentsText) {
    return `Performance self-review helper: This tool asks smart questions to help employees explain their work and accomplishments\n\
    Company Pillars: ${companyPillarsText}\n\
    Role Expectations: ${roleExpectationsText}\n\
    Accomplishments: ${accomplishmentsText}\n\
    \n\
    Ask for clarifications or more specifics on any projects or accomplishments which need more information:\n\
    `
}

function getSQLExplainerPrompt(queryText) {
    return `\
Query Explainer is a large language model trained to explain SQL queries to humans. It provides natural-sounding responses that are coherent and seek to not only answer the question but explain the concepts behind them.
Query Explainer
Explains what the input statement does in plain english, and some of the questions that could be answered by the query. If the input isn't a SQL statement, ask the user to try again.
Output Format:
**Plain English Explanation**:
$(Plain english explanation)
**Questions this query could help answer:**
- $(question 1)
- $(question 2)
<etc.>
###
Example
Input:
SELECT 
p.patient_id,
p.first_name,
p.last_name
FROM admissions a
JOIN patients p
ON p.patient_id = a.patient_id
WHERE EXTRACT(DAY from AGE(discharge_date, admission_date)) > 42;

Output: 
**Plain English Explanation**:
TL;DR: This query gets patients who have been admitted for longer than 42 days.

The query joins *admissions* and *patients* tables on the *patient_id* field, and filters results on the condition where the difference in days between the admission date and the discharge date is greater than 42. 

The AGE function gets the difference between *discharge_date* and *admission_date* and  the EXTRACT function is used to convert that difference from the result.

**Questions this query could help answer**:
-Which patients were admitted for more than 42 days?
-What is the patient information for patients who were admitted for longer than 42 days?
###
Input:
${queryText}
Output:
`
}

const getDocumentTypePrompt = (documentText, possibleOptions) => {
    return`
${documentText}

------
Possible document types:
${possibleOptions.map((option, i) => `${option}\n`).join('')}Unknown Type

What type of document is the above document? Use only options in this list, and respond using the exact text given.
Most likely document type:
`
}

const getLinterPrompt = (lintRulesText, documentText) => {
    return `
Document Linter Tool
Purpose: Reads a document and answers questions about it.
Example question:
Has author and team name?
Example answer:
Author and team name ❌
------
Input:

${documentText}
------
Assess the above document
Document Type: $(document type eg: Product Requirements Document, Email, Proposal, Article)
Questions:
${lintRulesText.split('\n').map((rule, i) => `${rule}\n`).join('')}
Answers. For yes/no answers use ✅ or ❌:
`
}

export {getDocumentTypePrompt, getLinterPrompt, getSQLExplainerPrompt, getExpandPSCPrompt, getPSCPrompt, getRecipePrompt, getDocEditorPrompt, getDocFeedbackPrompt, initialDocEditPrompt, getSQLGeneratorPrompt}