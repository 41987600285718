import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { TextField, Stack, Typography, Button, Divider, Link,DialogContent} from '@mui/material';


function HelpDialog(props) {
  const { onClose, open, sendFeedback} = props;
  const [feedbackText, setFeedbackText] = useState('');

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog fullWidth={true} maxWidth='sm' onClose={handleClose} open={open} >
      <DialogTitle>Support and feedback
      <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

      </DialogTitle>
      <DialogContent>
      <Stack divider={<Divider orientation='horizontal' flexItem />}>
        <Stack>
            <TextField multiline value={feedbackText} onChange={(e) => setFeedbackText(e.target.value)} placeholder={'Feedback on SQL AI?\nNew Product Ideas? Send em!'}></TextField>
            <Stack direction='row-reverse'>
                <Button variant='text' onClick={() => {
                    sendFeedback(feedbackText)
                    setFeedbackText('')
                    handleClose();
                }}>Send Feedback</Button>
            </Stack>
        </Stack>
        
        <Stack spacing={2}>
            <Link href='https://twitter.com/robbalian' target='_blank'>
                <Typography variant='subtitle2'>Built by @robbalian</Typography>
            </Link>
            <Stack direction='row' spacing={2}>
                <Button fullWidth href='mailto://balian.rob@gmail.com' target='_blank' variant='contained'>Email</Button>
                <Button fullWidth href='https://twitter.com/robbalian' target='_blank' variant='contained'>Twitter</Button>
                <Button fullWidth href='sms://+19413024516' target='_blank' variant='contained'>Text Rob</Button>
            </Stack>
        </Stack>
      </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default HelpDialog;