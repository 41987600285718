// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import "firebase/compat/analytics"
import RecipePage from "./RecipePage.js"
import TestPage from "./TestPage.js"

import DocEditorPage from "./DocEditorPage.js"
import DocFeedbackPage from "./DocFeedbackPage.js"
import PSCWriterPage from './PSCWriterPage.js'
import {SQLPage} from "./SQLPage.js"
import {LinterPage} from "./LinterPage.js"
import SQLStatsPage from './sql_stats.js';

import Home from "./Home.js"
import DifferentialDiagnosisPage from "./DifferentialDiagnosisPage.js"
import './App.css'
import {Container} from '@mui/material'
import {createTheme, ThemeProvider } from '@mui/material/styles';
import {HelmetProvider, Helmet} from 'react-helmet-async';

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from 'react-router-dom'
import ReactGA from 'react-ga'
import { BlogIndexPage } from './BlogIndexPage.js';

let theme = createTheme({
  palette: {
    mode:'light',
    primary: {
      main: '#3f51b5',
    },
    secondary: {
      main: '#F50057',
    },
    background:{
      default:'#f8f8f8'
    }
  },
  typography:{
    h5: {
      fontWeight:500
    },
    h1: {
      fontWeight:800
    }
  },
  spacing: 8,
  shape: {
    borderRadius: 2,
  },
  components: {
    MuiAppBar: {
      defaultProps:{
        elevation: 0,  
        color:'transparent'
        
      }
    },
    MuiTabs: {
      defaultProps:{
        elevation:8
      }
    }
  }
});

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBDtboAtr_Uj22Z8oLqJH3q6g1ucmZONK4",
  authDomain: "ai-site-8f8fe.firebaseapp.com",
  projectId: "ai-site-8f8fe",
  storageBucket: "ai-site-8f8fe.appspot.com",
  messagingSenderId: "603992739621",
  appId: "1:603992739621:web:a5073bdd2aa772a46d54cc",
  measurementId: "G-DZMH9EE163"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig); 

//const TRACKING_ID = "G-60GD5PKBNW"; // OUR_TRACKING_ID
//ReactGA.initialize(TRACKING_ID);
const analytics = firebase.analytics();

const helmetContext = {};

const App = () => {
  return (
    <HelmetProvider context={helmetContext}>
    <ThemeProvider theme={theme}>
    <Container disableGutters={true} maxWidth={false} sx={{bgcolor:'background.default'}}>
    <Router>
      <Routes>
        <Route exact path='' element={<SQLPage />} />
        <Route path="english-to-sql" element={<SQLPage />} />
        <Route path="sql-explainer" element={<SQLPage />} />
        <Route path="blog/:routePostId" element={<SQLPage />} />
        <Route path="blog" element={<SQLPage />} />
        <Route path="/recipe" element={<RecipePage />}/>
        <Route path="/docs" element={<DocEditorPage />}/>
        <Route path="/feedback" element={<DocFeedbackPage />}/>
        <Route path="/lint" element={<LinterPage />}/>
        <Route path="/test" element={<TestPage />}/>
        <Route path='/diagnosis' element={<DifferentialDiagnosisPage />}/>
        <Route path='/psc' element={<PSCWriterPage />}/>  
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/sqlStats" element={<SQLStatsPage />} />
      </Routes>
  </Router>
  </Container>
  </ThemeProvider>
  </HelmetProvider>
  );
};

export default App;