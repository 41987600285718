import { Typography, Container, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import {Light as SyntaxHighlighter} from 'react-syntax-highlighter';
import sql from 'react-syntax-highlighter/dist/esm/languages/hljs/sql'
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import 'firebase/compat/firestore';
import firebase from 'firebase/compat/app';
import { MDEEditable } from "./SimpleMDE";
import {getAPICompletion} from "./api"
import {SSE} from "./sse.js"
  

// For Node.js
var TurndownService = require('turndown').default
var turndownService = new TurndownService()
.addRule('img', {
  filter: ['span'],
  replacement: (content, node) => {
    let replacement = content
    console.log(`content: ${replacement}`)
    if (node.style.fontStyle == 'italic') {
      replacement = '_' + replacement + '_'
    }
    if (node.style.fontWeight > 400) {
      replacement = '**' + replacement + '**'
    }
    console.log(node.style.fontStyle)
    console.log(node)
    //node.outerHTML
    return replacement
  }
}).addRule('img2', {
  filter: ['b'],
  replacement: (content, node) => {
    return content
  }
})

const TestPage = () => {
  const [outputText, setOutputText] = useState('')
  const [mdeText, setMDEText] = useState('')

  useEffect(() => {
    //testServer()
    testStreamServer()

  },[])

  const testServer = async () => {
    let response = await fetch(
      "https://tidyheartyassembly.robbalian.repl.co/test",
      {
      method: "GET"
      }
    )
    console.log(response)
  }

  const testStreamServer = async () => {
    let source = getTestStreamSource()
    source.addEventListener('message', function(e) {
      console.log(e)
      const json = JSON.parse(e.data);
      const completion = json.choices[0].text;
      setOutputText(prevData => (prevData + completion).trimStart())
    });
    source.stream();
  }

  const API_ENDPOINT = "https://api.openai.com/v1/completions";
  const getTestStreamSource = (prompt="write the chorus of the newest rhyming Artificial Intelligence rap hit 'straight outta prompton.' You must use the word prompton") => {
    let source = new SSE("https://tidyheartyassembly.robbalian.repl.co/stream", {
        method: "POST",
        headers: {
        Authorization: `Bearer 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`,
        "Content-Type": "application/json"
        },
        payload: JSON.stringify({
        "prompt": prompt,
      })
    })
      return source
  }

return (
      <Container>
        <h2>hello</h2> 
        <span style={{'white-space': 'pre-line'}}>{outputText}</span>       
      </Container>
      )
}
export default TestPage