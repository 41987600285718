import React, { useState, useEffect, createContext } from "react";
import "easymde/dist/easymde.min.css";
import {getSQLGeneratorPrompt} from "./prompts";
import {getAPICompletion, getAPIStreamSourceChat} from "./api.js"
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/functions'
import {onAuthStateChanged} from 'firebase/auth'
import {Stack,Button, IconButton, Typography, Grid, TextField, Fab, Select, MenuItem,FormControl,InputLabel, Box, Divider, Link, List, ListItemButton, Tabs, Tab} from '@mui/material'
import LoginDialog from "./components/LoginDialog";
import TabPanel from "./components/TabPanel"
import DynamicAppBar from "./components/DynamicAppBar";
import TableInput from "./components/TableInput";
import {Light as SyntaxHighlighter} from 'react-syntax-highlighter';
import sql from 'react-syntax-highlighter/dist/esm/languages/hljs/sql'
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import HelpDialog from "./components/HelpDialog";
import UpgradeDialog from "./components/UpgradeDialog";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import {defaultSQLTables, defaultSQLQueryText} from './default_text'
import SQLExplainerView from './components/SQLExplainerView'
import {setLocalStorage, getLocalStorage} from './utils.js'
import SplashPage from './components/SplashPage'
import { BlogIndexPage, BlogPost } from "./BlogIndexPage";
import {useNavigate, useLocation} from 'react-router-dom'


SyntaxHighlighter.registerLanguage('sql', sql);

const InputContext = createContext({});
const startingTrialQueries = 8;


const SQLPage = () => {
  const analytics = firebase.analytics();

  let navigate = useNavigate()
  const {pathname} = useLocation();
  console.log(pathname)
  let initialTab = 0;
  if (pathname.includes('/blog')) {
    initialTab = 3
  } else if (pathname == '/sql-explainer') {
    initialTab= 2
  } else if (pathname == '/english-to-sql') {
    initialTab = 1
  }

  const SQLOptions = ['SQLite', 'Postgres SQL']
  let initialTrialQueries = getLocalStorage('trialQueriesRemaining') ?? startingTrialQueries

  const isPaidSubcriber = () => {
    return subscriptionInfo && subscriptionInfo.status == 'active'
  }
  const trialOver = () => trialQueriesRemaining <= 0 && !isPaidSubcriber()

  let initialTables = getLocalStorage('tables')
  if (initialTables == null || initialTables.length == 0) {
    initialTables = (defaultSQLTables);
  }

  const [tables, setTables] = useState(initialTables);
  const [sqlType, setSQLType] = useState(getLocalStorage('sqlType') ? getLocalStorage('sqlType') : SQLOptions[1]);
  const [queryText, setQueryText] = useState(getLocalStorage('queryText') ? getLocalStorage('queryText') : defaultSQLQueryText);
  const [outputText, setOutputText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [supportModalOpen, setSupportModalOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [subscriptionInfo, setSubscriptionInfo] = useState(null);
  const [queryFeedbackText, setQueryFeedbackText] = useState('')
  const [queryID, setQueryID] = useState(null)
  const [trialQueriesRemaining, setTrialQueriesRemaining] = useState(initialTrialQueries)
  const [semiStructuredText, setSemiStructuredText] = useState('');
  const [queryOutputText, setQueryOutputText] = useState('');
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState(false)
  const [debugQueries, setDebugQueries] = useState([])
  const [selectedTabIndex, setSelectedTabIndex] = useState(initialTab); //getLocalStorage('selectedTabIndex') ? getLocalStorage('selectedTabIndex') : 0



  const handleTabChange = (event, newValue) => {
    setSelectedTabIndex(newValue);
    //navigate('/hello')
  };

  const inputContextValue = {
    tables: tables,
    setTables: setTables,
    trialQueriesRemaining: trialQueriesRemaining,
    setTrialQueriesRemaining: setTrialQueriesRemaining,
    user: user,
    setUser: setUser
  }

  useEffect(() => {
    onAuthStateChanged(firebase.auth(), (user) => {
      //console.log(user)
      if (user) {
        setUser({name:user.displayName, email:user.email, id:user.uid})
        analytics.setUserProperties({email:user.email, user_id:user.uid})
        // log analytics event "logged in"
        analytics.logEvent('login')
      } else {
        setUser(null)
      }
    })
    document.title = 'SQL Genius AI- Write SQL easily using AI. No code required'
  }, [])

  useEffect(() => {
    setLocalStorage('tables', tables);
    setLocalStorage('queryText', queryText);
    setLocalStorage('sqlType', sqlType);
    setLocalStorage('trialQueriesRemaining', trialQueriesRemaining);
    setLocalStorage('selectedTabIndex', selectedTabIndex);
  }, [tables, queryText, sqlType, trialQueriesRemaining, selectedTabIndex])

  useEffect(() => {
    const trialOverLimitChars = 250 
    //console.log("updated outputText: " + outputText)
    if (trialOver() && outputText.length > trialOverLimitChars) {
      setQueryOutputText(outputText.slice(0, trialOverLimitChars)+'...\n\n\--Please upgrade to generate unlimited queries!')
    } else {
      setQueryOutputText(outputText)
    }
  }, [outputText])

  const getQueries = async () => {
    console.log(`querying with id ${user.id}`)
    const db = firebase.firestore();    
    const docRef = await db.collection('queries')
    .orderBy("timestamp","desc")
    .limit(60)
    .onSnapshot(async (snapshot) => {
      // In this implementation we only expect one active or trialing subscription to exist.
      const doc = snapshot.docs[0];
      let queries = snapshot.docs.map((doc) => doc.data())
      setDebugQueries(queries)
    })
  }


  const splitOutputAndSemistructured = (text) => {
    let result = text.match(/--Semi-structured interpretation: ([\s\S]*?)\n--/);
    let first = ''
    if (result === null) {
      first = text
    } else {
      first = result[1]
    }

    let second = ''
    let result2 = text.match(/--Output SQL Query:[\s\S]*/)
    if (result2 === null) {
      second = ''
    } else {
      second = result2[0]
    }
    return [first, second];
  }

  useEffect(() => {
    if (user != null) {
      getSubscriptionStatus()
      console.log(user.email)
      if (user.email == 'balian.rob@gmail.com') getQueries()
    }
  }, [user])

  useEffect(() => {
    if (isLoading == false && outputText != '') {
      //console.log("Done loading. outputText:"+outputText)
      setTrialQueriesRemaining(prevValue => Math.max(prevValue-1, 0))
      logQuery()
    }
  },[isLoading])

  const handleClose = () => {
    setModalOpen(false);
  }

  const getSubscriptionStatus = async () => {
    const db = firebase.firestore();
    console.log(`querying with id ${user.id}`)
    const docRef = await db
    .collection('customers')
    .doc(user.id)
    .collection('subscriptions')
    .where('status', 'in', ['trialing', 'active'])
    .onSnapshot(async (snapshot) => {
      // In this implementation we only expect one active or trialing subscription to exist.
      const doc = snapshot.docs[0];
      if (doc) {
        console.log(doc.id, ' => ', doc.data());
        setSubscriptionInfo(doc.data())
        analytics.setUserProperties({subscription_status:'subscriber'})
      } else {
        setSubscriptionInfo(null)
        analytics.setUserProperties({subscription_status:'none'})
        console.log('No subscription found for user')
      }
    });
  }

  const manageSubscription = async () => {
    console.log('attempting to manage subscription')
    const functionRef = firebase
    .functions()
    .httpsCallable('ext-firestore-stripe-payments-createPortalLink');
  const { data } = await functionRef({
    returnUrl: window.location.href,
    locale: "auto", // Optional, defaults to "auto"
    //configuration: "bpc_1JSEAKHYgolSBA358VNoc2Hs", // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
  });
  window.location.assign(data.url);
  }

  const startUpgrade = async (isMonthly=false) => {
    analytics.logEvent('start_upgrade')

    const price = isMonthly ? 'price_1Lzv6bAwThEI823WcNHzrWHY' : 'price_1Lzv6bAwThEI823WVhTrnURE'

    //return
    const db = firebase.firestore();
    const docRef = await db
    .collection('customers')
    .doc(user.id)
    .collection('checkout_sessions')
    .add({
      price: price, // Production product: 'price_1Lzv6bAwThEI823WcNHzrWHY',
      success_url: window.location.origin + '/sql',
      cancel_url: window.location.origin  + '/sql',
    });
    // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot((snap) => {
      const { error, url } = snap.data();
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url);
      }
    });
  }

  const sendFeedback = async (feedback) => {
    const db = firebase.firestore();
    db.collection("feedback").add({
        user: user ? user : 'anonymous',
        feedback: feedback,
        timestamp: firebase.firestore.FieldValue.serverTimestamp()
      })
    .then((docRef) => {
        console.log("Document written with ID: ", docRef.id);
    })
  }
  
  const logQuery = async () => {
    const db = firebase.firestore();
    const docRef = await db.collection('queries').add({
      queryText: queryText,
      tables: tables,
      outputText: queryOutputText,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      userId: user ? user.id : 'anonymous',
    })
    console.log('query logged with ID: ' + docRef.id + ' and outputText: ' + queryOutputText)
    setQueryID(docRef.id);
  }

  const handleDebugQueryClick = (item) => {
    setQueryText(item.queryText)
    setTables(item.tables)
    setOutputText(item.outputText)
  }

  const sendQueryFeedback = async (isPositive) => {
    if (!queryID) return
    const db = firebase.firestore();
    let updateDict = {isPositive:isPositive}
    if (queryFeedbackText != '') {
      updateDict['queryFeedback'] = queryFeedbackText
    }
    
    const docRef = await db.collection('queries').doc(queryID).update(updateDict)
    console.log('query feedback logged to id ID: ' + queryID)
    setQueryFeedbackText('')
  }

  const makeApiRequest = async (feedbackAuthor) => {
    if (isLoading) return
    let prompt = getSQLGeneratorPrompt(sqlType, tables, queryText);
    setIsLoading(true)
    let response = await getAPICompletion(prompt, {temperature:0})
    console.log(response)
    setOutputText(response)
    setIsLoading(false)
    
    // let source = getAPIStreamSourceChat(prompt)
    // setOutputText('') // add some new lines
    // source.addEventListener('message', function(e) {
    //   // ONLY SET CALLS INSIDE LISTENERS. NO FUNCTION CALLS DIRECTLY, NO REFERENCING OUTSIDE VARS PLEASE
    //     if (e.data == "[DONE]") {
    //         setIsLoading(false)
    //         return
    //     }
    //     const json = JSON.parse(e.data);
    //     const delta = json.choices[0].delta;
    //     const completion = delta.content ? delta.content : ''
    //     setOutputText(prevData => (prevData + completion).trimStart())
    // });
    // source.stream();
  }; 

  const queryTextSplit = splitOutputAndSemistructured(queryText)

return (
  <Box sx={{display:'flex', flexDirection:'column'}}>
    <InputContext.Provider value={inputContextValue}>
    <DynamicAppBar 
        user={user} 
        subscriptionInfo={subscriptionInfo} 
        onSignupLoginClick={() => setModalOpen(true)}
        onManageSubscriptionClick={manageSubscription}
        onUpgradeClick={() =>setUpgradeDialogOpen(true)}
        onPricingButtonClick={() => setUpgradeDialogOpen(true)}
        onInfoButtonClick={() => setSupportModalOpen(true)}
        />
      <Tabs onChange={handleTabChange} value={selectedTabIndex} textColor='secondary' indicatorColor='secondary'>
        <Tab component={Link} href='/' label='Splash Page' />
        <Tab component={Link} href='/english-to-sql' label='English->SQL' />
        <Tab component={Link} href='/sql-explainer' label='SQL Explainer' />
        <Tab component={Link} href='/blog' label='Blog' />
      </Tabs>
      <TabPanel value={selectedTabIndex} index={0}>
        <SplashPage onButtonClick={() => setSelectedTabIndex(1)}/>
      </TabPanel>
      <TabPanel value={selectedTabIndex} index={1}>
        <Grid container spacing={2} sx={{p:2}}>
          <Grid item xs sx={{minWidth:'300px'}}>
            <Typography variant='h6'>My Tables</Typography>
            <Stack spacing={2}>
                <TableInput />
                <FormControl size="small">
              <InputLabel id="demo-simple-select-helper-label">Advanced: SQL Type</InputLabel>
              <Select
                label="Advanced: SQL Type"
                value={sqlType}
                onChange={e => setSQLType(e.target.value)} 
                >
                {SQLOptions.map((option, i) => ( 
                  <MenuItem key={i} value={option}>{option}</MenuItem>
                ))}
              </Select>
              </FormControl>
              <List>
              {debugQueries.map((item, i) => (
                <ListItemButton key={i} onClick={() => handleDebugQueryClick(item)}>
                  {item.queryText}
                </ListItemButton>
              ))}
            </List>
            </Stack>
          </Grid>
          <Grid item xs sx={{minWidth:'300px'}}>
            <Stack direction='row'>
              <Typography variant='h6'>My Query</Typography>
            </Stack>
            <Stack direction='column' sx={{m:1}}>
                  <TextField
                    variant='outlined'
                    multiline
                    placeholder='What do you want your query to do?' 
                    autoFocus={true} 
                    type='text' 
                    label='Sql query in English'
                    onChange={e => setQueryText(e.target.value)} 
                    onKeyDown={event => {
                      if (event.keyCode==13 && event.metaKey) {
                        makeApiRequest()
                      }
                    }}
                    sx={{flex:1}}
                    value={queryText}></TextField>
                    {semiStructuredText != '' &&
                <TextField
                variant='outlined'
                multiline
                placeholder='What the AI interpreted [editable]' 
                autoFocus={true} 
                type='text' 
                label='What the AI interpreted [editable]'
                onChange={e => setSemiStructuredText(e.target.value)} 
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    makeApiRequest()
                  }
                }}
                sx={{flex:1, marginTop:'10px'}}
                value={semiStructuredText}></TextField>
              }
                  <Button 
                    variant="contained"   
                    disabled={isLoading} 
                    onClick={() => makeApiRequest()} 
                    sx={{marginTop:2}}
                    >
                    Write SQL
                  </Button>
              </Stack>
              
            {queryOutputText != '' &&
            <div>
                <SyntaxHighlighter customStyle={{'backgroundColor':'#FFF'}} wrapLines={true} wrapLongLines={true} language="sql" style={docco}>
                  {queryOutputText}
                </SyntaxHighlighter>
                {!isPaidSubcriber() && (
            <Stack direction='row' sx={{p:'3px'}}>
              <Typography variant='body2' sx={{marginLeft:3, marginRight:3}}>Trial queries left: {trialQueriesRemaining}</Typography>
              <Link component='button' onClick={() => setUpgradeDialogOpen(true)}>Upgrade to unlimited</Link>
            </Stack>
              )}
            <Stack divider={<Divider orientation='vertical' flexItem />} direction='row' spacing={4}>
              <Stack direction='row' sx={{flex:1}}>
                <TextField sx={{flex:1}} onChange={(e) => setQueryFeedbackText(e.target.value)} value={queryFeedbackText} label='Query feedback' placeholder={'Send feedback on this query'}></TextField>
              <IconButton onClick={()=>sendQueryFeedback(true)} color="secondary" aria-label="add">
                    <ThumbUpIcon />
              </IconButton>
              <IconButton onClick={()=>sendQueryFeedback(false)} color="secondary" aria-label="add">
                    <ThumbDownIcon />
              </IconButton>
              </Stack>
              <Button
              
              variant='text'
              onClick={() => {
                navigator.clipboard.writeText(outputText)
              }
              } >
                Copy Text
              </Button>
            </Stack>
            </div>
            }
            
            </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={selectedTabIndex} index={2}>
        <SQLExplainerView />
      </TabPanel>
      <TabPanel value={selectedTabIndex} index={3}>
        <BlogIndexPage />
      </TabPanel>

      <LoginDialog
          onClose={handleClose}
          open={modalOpen}
        />
      <HelpDialog onClose={() => setSupportModalOpen(false)} open={supportModalOpen} sendFeedback={sendFeedback}/>
      <UpgradeDialog onClose={() => setUpgradeDialogOpen(false)} open={upgradeDialogOpen} onUpgradeButtonClick={startUpgrade} onSignupButtonClick={() => setModalOpen(true)} />

      <Fab onClick={()=>setSupportModalOpen(true)} size="medium" color="secondary" aria-label="add" sx={{position:'fixed', bottom:'16px', right:'16px'}}>
        <HelpOutlineIcon />
      </Fab>
    </InputContext.Provider>
    </Box>
)
}
export {SQLPage, InputContext}