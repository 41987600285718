const setLocalStorage = (key, value) => {
  //console.log(`SetLocalStorage(${key}, ${value})`)
  window.localStorage.setItem(key, JSON.stringify(value));
}

const getLocalStorage = (key) => {
  const item = window.localStorage.getItem(key)
  if (item) {
    return JSON.parse(item)
  }
  return null
}

export {setLocalStorage, getLocalStorage}