import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/default-highlight"
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {getAPIStreamSourceChat} from "../api.js"
import {TextField, Stack, Grid,Typography, Button} from '@mui/material'
import {getSQLExplainerPrompt} from '../prompts.js'
import React, {useState} from "react";
import {defaultSQLExplainerText} from '../default_text'
import {setLocalStorage, getLocalStorage} from '../utils.js'
import { MDEPreviewOnly } from "../SimpleMDE.js";

const SQLExplainerView = (props) => {
  const [queryText, setQueryText] = useState(defaultSQLExplainerText)
  const [isLoading, setIsLoading] = useState(false)
  const [outputText, setOutputText] = useState('')

  const makeApiRequest = async () => {
    if (isLoading) return
    let prompt = getSQLExplainerPrompt(queryText);
    let source = getAPIStreamSourceChat(prompt)
    console.log(prompt)
    setOutputText('') // add some new lines
    source.addEventListener('message', function(e) {
      // ONLY SET CALLS INSIDE LISTENERS. NO FUNCTION CALLS DIRECTLY, NO REFERENCING OUTSIDE VARS PLEASE
        if (e.data == "[DONE]") {
            setIsLoading(false)
            return
        }
        const json = JSON.parse(e.data);
        const delta = json.choices[0].delta;
        const completion = delta.content ? delta.content : ''
        setOutputText(prevData => (prevData + completion).trimStart())
    });
    source.stream();
    setIsLoading(true)
  }; 
  

  return (
  <Grid container direction='row' spacing={2} sx={{p:2}} justifyContent={'flex-start'} alignContent={'flex-start'}>
  <Grid item sx={{minWidth:'300px', flex:'1'}}>
    <Stack direction='row'>
      <Typography variant='h6'>Paste SQL Query</Typography>
    </Stack>
    <TextField 
      fullWidth
      multiline 
      value={queryText} 
      onChange={(e) => setQueryText(e.target.value)} 
      onKeyDown={event => {
        if (event.keyCode==13 && event.metaKey) {
          makeApiRequest()
        }
      }} />
    </Grid>
  <Grid item sx={{minWidth:'300px', flex:'1'}}>
  <Stack direction='row'>
    <Typography variant='h6'>Formatted Query</Typography>
  </Stack>
  <Stack direction='column' sx={{m:1}}>
    <SyntaxHighlighter customStyle={{'backgroundColor':'#FFF', 'marginBottom':'0px', 'marginTop':'0px'}} wrapLines={true} wrapLongLines={true} language="sql" style={docco}>
      {queryText}
    </SyntaxHighlighter>
    <Button 
      variant="contained"   
      disabled={isLoading} 
      onClick={() => makeApiRequest()} 
      sx={{marginTop:2}}
      >
      Explain SQL
    </Button>
  </Stack>
  </Grid>
  <Grid item sx={{width:'100%'}}>
  <Typography variant='h6'>Query explained </Typography>
    <MDEPreviewOnly value={outputText}>

    </MDEPreviewOnly>
  </Grid>
  </Grid>
  )
}
export default SQLExplainerView