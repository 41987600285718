import { MDEPreviewOnly } from "./SimpleMDE";
import React, { useState } from "react";
import "easymde/dist/easymde.min.css";
import { getRecipePrompt } from "./prompts";
import {getAPIStreamSourceChat} from "./api.js"


const RecipePage = () => {
  const [inputText, setInputText] = useState("");
  const [outputText, setOutputText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  
  const makeApiRequest = async () => {
    let prompt = getRecipePrompt(inputText)
    let source = getAPIStreamSourceChat(prompt)    
    source.addEventListener('message', function(e) {
        console.log(e.data);
        if (e.data == "[DONE]") {
            setIsLoading(false)
            return
        }
        const json = JSON.parse(e.data);
        
        const completion = json.choices[0].text;
        setOutputText(prevData => prevData + completion)
    });
    source.stream();

    setIsLoading(true)
    setOutputText('')
  };

const buttonText = isLoading ? 'Generating...' : 'Generate Recipe';
return (
  <div className="App">
        <header className="App-header">
        <div className="title">Recipe AI</div>
        <div className="flex-row-container">
            <input placeholder='What do you want to make? Eg: Avocado Cherry pie' autoFocus={true} type='text' onChange={e => setInputText(e.target.value)} value={inputText}></input>
            <button className='full-height-button' disabled={isLoading} onClick={makeApiRequest}>{buttonText}</button>
        </div>
        <MDEPreviewOnly 
            value={outputText}
            onChange={e => setOutputText(e)}
        />
    </header>
  </div>
)
}
export default RecipePage