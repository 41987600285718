import { Typography, Box, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState } from "react";
import { getRecipePrompt } from "./prompts";

const DifferentialDiagnosisPage = () => {
  const [outputText, setOutputText] = useState('')

  const splitOutputAndSemistructured = (outputText) => {
    const delimiter = "Output SQL Query:"
    var index = outputText.indexOf(delimiter);
    if (index == -1) {
      return [outputText,'']
    }
    var first = outputText.substring(0, index);
    var second = outputText.substring(index + delimiter.length +1);
    return [first, second];
  }

  const semiStructured = () => {
    return splitOutputAndSemistructured(outputText)[0]
  }

  const outputQuery = () => {
    return splitOutputAndSemistructured(outputText)[1]
  }

return (
  <Box sx={{backgroundColor:'#EEE', height:'100%'}}>
        <Typography variant='h6'>Differential Diagnosis Bot</Typography>
        <Stack>
          <Typography>{semiStructured()}</Typography>
          <TextField sx={{width:'100%'}} onChange={e => setOutputText(e.target.value)} value={outputText}></TextField>
          <Typography>{semiStructured()}</Typography>
          <Typography>{outputQuery()}</Typography>
        </Stack>
      </Box>
      )
}
export default DifferentialDiagnosisPage