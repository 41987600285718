import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useState, useContext} from 'react';
import { TextField, Stack, Typography, Button, Divider, Link,DialogContent, ListItem, List, Grid, Card} from '@mui/material';
import {InputContext} from '../SQLPage.js'


function UpgradeDialog(props) {
  const { onClose, open, onUpgradeButtonClick, onSignupButtonClick} = props;
  const [feedbackText, setFeedbackText] = useState('');
  const { user } = useContext(InputContext);


  const benefits = ['Unlimited queries','Save up to 15 tables at a time','Multiple joins, window functions, partitions, unnesting','Stellar customer support','Get more done without Googling', 'Spend less time bugging your data folks ❤️']
  const handleClose = () => {
    onClose();
  };

  const handleClick = (isMonthly) => {
    if (user) {
      onUpgradeButtonClick(isMonthly)
    } else {
      onSignupButtonClick()
    }
    handleClose()
  }

  return (
    <Dialog fullWidth={true} maxWidth='sm' onClose={handleClose} open={open} >
      <DialogTitle>
      <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

      </DialogTitle>
      <DialogContent>
      <Stack>
        <Typography color='primary' variant='h5'>Get more done: Upgrade SQL Genius</Typography>
        <List className={'benefitsList'} sx={{listStyleType:'disc', pl:4}}>
          {benefits.map((benefit,i) => (
            <ListItem key={i} sx={{display:'list-item'}}>{benefit}</ListItem>
          ))}
        </List>
      </Stack>
      <Grid container direction='row' spacing={4}>
          {[{price:'$10', term:'monthly'},{price:'$99', term:'yearly'}].map((option, i) => (
                    <Grid key={i} item xs={6} sx={{}}>
                    <Card sx={{p:3}}>
                    <Typography sx={{marginBottom:0, textAlign:'center'}} variant="h3" gutterBottom>
                      {option.price}
                    </Typography>
                    <Typography sx={{marginTop:0, textAlign:'center'}} variant="h6" gutterBottom>
                      {option.term}
                    </Typography>
                    <Button color='secondary' variant="contained" fullWidth onClick={() => handleClick(option.term=='monthly')}>
                      {user ? 'Choose ' + option.term : 'Sign up to start'}
                    </Button>
                    </Card>
                    </Grid>          
          ))
        }          
    </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default UpgradeDialog;
//divider={<Divider orientation='horizontal' flexItem />}