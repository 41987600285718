import { Typography, Box, TextField, Button } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState, useRef, createRef} from "react";
import {MDEEditable} from './SimpleMDE.js'
import {getPSCPrompt, getExpandPSCPrompt} from "./prompts.js";
import { getAPIStreamSourceChat } from "./api.js";
import { getLocalStorage, setLocalStorage } from "./utils.js";
import {defaultPSCText} from './default_text.js'

const PSCWriterPage = () => {
  const [nameText, setNameText] = useState(getLocalStorage('nameText') ?? defaultPSCText['nameText'])
  const [companyPillarsText, setCompanyPillarsText] = useState(getLocalStorage('companyPillarsText') ?? defaultPSCText['companyPillarsText'])
  const [roleExpectationsText, setRoleExpectationsText] = useState(getLocalStorage('roleExpectationsText') ?? defaultPSCText['roleExpectationsText'])
  const [accomplishmentsText, setAccomplishmentsText] = useState(getLocalStorage('accomplishmentsText') ?? defaultPSCText['accomplishmentsText'])
  const [outputText, setOutputText] = useState(getLocalStorage('outputText'))
  const [questionsText, setQuestionsText] = useState('')
  const [prefixText, setPrefixText] = useState('')
  const [suffixText, setSuffixText] = useState('')
  const [mdeInstance, setMdeInstance] = useState(null)

  const ref = useRef('')

  useEffect(() => {
    document.title = 'AI Performance Review Writer';
  });


  useEffect(() => {
    setLocalStorage('nameText', nameText)
    setLocalStorage('companyPillarsText', companyPillarsText)
    setLocalStorage('roleExpectationsText', roleExpectationsText)
    setLocalStorage('accomplishmentsText', accomplishmentsText)
    setLocalStorage('outputText', outputText)
  }, [companyPillarsText, roleExpectationsText, accomplishmentsText, outputText])

  useEffect(() => {
    console.log(`Prefix text changing: ${prefixText}`)
  },[prefixText])

  const test = (prefixText, suffixText) => {
    console.log(prefixText)
  }

  const complete = async (prefixText, suffixText, max_tokens) => {
    let prompt = getPSCPrompt(companyPillarsText, roleExpectationsText, accomplishmentsText, prefixText)
    
    console.log(prompt)
    console.log(prefixText)
    console.log(suffixText)
    let source = getAPIStreamSourceChat(prompt, {model:'text-davinci-003', max_tokens:max_tokens, suffix:suffixText})    
    source.addEventListener('message', function(e) {
        console.log(e.data);
        if (e.data == "[DONE]") {
            return
        }
        const json = JSON.parse(e.data);
        const completion = json.choices[0].text;
        let mdeInstance = ref.current
        const pos = mdeInstance.codemirror.getCursor()
        mdeInstance.codemirror.replaceRange(completion, pos)
        mdeInstance.codemirror.focus()
      });
    source.stream();
  }

  const expand = async () => {
    let prompt = getExpandPSCPrompt(companyPillarsText, roleExpectationsText, accomplishmentsText)   
    let source = getAPIStreamSourceChat(prompt)    
    source.addEventListener('message', function(e) {
        console.log(e.data);
        if (e.data == "[DONE]") {
            return
        }
        const json = JSON.parse(e.data);
        
        const completion = json.choices[0].text;
        setQuestionsText(prevData => prevData + completion)
    });
    source.stream();

    //setIsLoading(true)
    //setOutputText('')
  }

return (
  <Box sx={{backgroundColor:'#EEE', height:'100%'}}>
        <Typography variant='h6'>Self-Review Writer Bot</Typography>
        <Stack sx={{margin:3}} spacing={2}>
            <Stack direction='row'>
              <TextField fullWidth sx={{}} variant='outlined' label='First Name' placeholder='First name' onChange={e => setNameText(e.target.value)} value={nameText}></TextField>
              <TextField fullWidth sx={{}} variant='outlined' label='Company Pillars' placeholder='eg: Impact, intention' onChange={e => setCompanyPillarsText(e.target.value)} value={companyPillarsText}></TextField>
            </Stack>
            <TextField sx={{}} variant='outlined' multiline label='Role Expecations' placeholder='eg: Lead large engineering teams, architect and ship feature and product-level projects, hire new engineers' onChange={e => setRoleExpectationsText(e.target.value)} value={roleExpectationsText}></TextField>
            <TextField sx={{}} variant='outlined' multiline label='Your Accomplishments' placeholder='Bullet format, include as much as possible' onChange={e => setAccomplishmentsText(e.target.value)} value={accomplishmentsText}></TextField>
            <Typography sx={{whiteSpace: 'pre-line'}} variant='body'>{questionsText}</Typography>

            <Stack direction='row' spacing={2}>
            
            <Button variant='contained' onClick={expand}>Expand Accomplishments</Button>
            <Button variant='contained' onClick={e => setQuestionsText('')}>Clear</Button>
            </Stack>
            <Typography variant='h6'>Your Self-Review</Typography>        
            <MDEEditable value={outputText} 
              setMdeInstance={setMdeInstance}
              onCursorChanged={(prefixText, suffixText) => {
                setPrefixText(prefixText)
                setSuffixText(suffixText)
              }} 
              completeKeyPressed={complete}
              onChange={e => setOutputText(e)}
              ref = {ref}
              />
            <Stack direction='row' spacing={2}>
              <Button variant='contained' onClick={e => {complete(outputText, '', 500)}}>Complete Full Review</Button>
              <Button variant='contained' onClick={e => {complete(prefixText, suffixText, 25)}}>Complete Next Sentence (Cmd-Enter)</Button>
        </Stack>
        </Stack>
      </Box>
      )
}
export default PSCWriterPage