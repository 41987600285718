import { Typography, Chip } from "@mui/material"

const AccountInfo = (props) => {
    const {user, subscriptionInfo} = props
    let subscriptionStatus = 'Basic'
    let subscriptionColor = 'default'
    if (subscriptionInfo != null && subscriptionInfo.status == 'active') {
        subscriptionStatus = 'Pro Subscriber'
        subscriptionColor = 'success'
    }
    let label = (<Typography variant='subtitle2'>{subscriptionStatus}</Typography>)
    return (
        <div className="topMenuItem">
            <Typography variant='h6'>{user.name}</Typography>
            <Typography variant='subtitle1'>{user.email}</Typography>
            <Chip label={label} color={subscriptionColor} size='small'>{}</Chip>
        </div>
    )
}

export default AccountInfo