import {AppBar, Toolbar, Button, Typography, MenuItem, Menu, Stack, Box} from '@mui/material'
import AccountInfo from "./AccountInfo";
import {IconButton} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu'
import firebase from 'firebase/compat/app';
import React, { useState } from "react";
import { useContext } from 'react';
import { InputContext } from '../SQLPage';

const DynamicAppBar = ({user, subscriptionInfo, onSignupLoginClick, onManageSubscriptionClick, onUpgradeClick, onInfoButtonClick, onPricingButtonClick, additionalComponents}) => {
    const { trialQueriesRemaining } = useContext(InputContext);
    const [anchorEl, setAnchorEl] = useState(null)
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleOpenNavMenu = (e) => {setAnchorElNav(e.currentTarget)}
    const handleCloseNavMenu = () => {setAnchorElNav(null)}

    const accountMenu = user && (
    <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        >
        <AccountInfo user={user} subscriptionInfo={subscriptionInfo}/>
        {subscriptionInfo && subscriptionInfo.status == 'active' ?
        <MenuItem onClick={onManageSubscriptionClick}>Manage Subscription</MenuItem>
        :
        <MenuItem onClick={() => {
            handleMenuClose()
            onUpgradeClick()
        }}>Upgrade to Pro</MenuItem>}
        <MenuItem onClick={() => {
            handleMenuClose()
            onInfoButtonClick()
            }}>Help</MenuItem>
        <MenuItem onClick={() => {
            handleMenuClose()
            firebase.auth().signOut()
            }}
            sx={{color:'red'}}>Log Out</MenuItem>
        </Menu>)

    const pages= !user ? [
        {name:'Feedback',action:onInfoButtonClick},
        {name:'Pricing',action:onPricingButtonClick},
        {name:'Sign Up / Log In',action:onSignupLoginClick}
    ] : 
    [{name:'Account',action:handleMenu}]

    

    const buttons =
    (<Stack direction='row' sx={{display:{xs:'none', md:'flex'}}}>
        {pages.map((page) => (
            <Button key={page.name} onClick={page.action}>{page.name}</Button>
        ))}
    </Stack>)

    return (
        <AppBar position='static' elevation={1}>
            <Toolbar sx={{marginTop:0, marginBottom:0}}>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
                >
                <MenuIcon />
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                    }}
                    >
                    {pages.map((page) => (
                        <MenuItem key={page.name} onClick={(e) => {
                            handleCloseNavMenu()
                            page.action(e)
                            }}>
                        <Typography textAlign="center">{page.name}</Typography>
                        </MenuItem>
                    ))}
                </Menu>
            </Box>
                <Typography variant="h5" component="div" color='primary' sx={{ flexGrow: 1 }}>
                SQL Genius (beta)
                </Typography>
                {buttons}   
                {accountMenu}
            </Toolbar>   
        </AppBar>
        )
}

export default DynamicAppBar