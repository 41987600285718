import React, { useState, useEffect, useCallback, useMemo, forwardRef } from "react";
import SimpleMDE from "react-simplemde-editor";
import CodeMirror from "codemirror"

const MDEPreviewOnly = (props) => {
  const [value, setValue] = useState("Initial");
  const [simpleMdeInstance, setMdeInstance] = useState(null);


  const getMdeInstanceCallback = useCallback((simpleMde) => {
    if (simpleMde && simpleMde.isPreviewActive() == false) {
      simpleMde.togglePreview()
    }
    setMdeInstance(simpleMde);
  }, []);

  useEffect(() => {
    simpleMdeInstance &&
      console.info("Hey I'm editor instance!", simpleMdeInstance);
  }, [simpleMdeInstance]);


  const autofocusNoSpellcheckerOptions = useMemo(() => {
    return {
      autofocus: false,
      spellChecker: false,
      toolbar: false,
      status: false
    }
  }, []);

  return (
    <SimpleMDE 
      options={autofocusNoSpellcheckerOptions}
      value={props.value}
      onChange={props.onChange}
      getMdeInstance={getMdeInstanceCallback}
    />
  );
};

const MDEEditable = forwardRef((props, ref) => {
    const [simpleMdeInstance, setSimpleMdeInstance] = useState(null);
    let maxHeight = undefined
    if ('maxHeight' in props) {
      maxHeight=props['maxHeight']
    }
    const shouldAutoFocus = 'shouldAutoFocus' in props ? props['shouldAutoFocus'] : true

    const getMdeInstanceCallback = useCallback((simpleMde) => {
      if (!simpleMdeInstance && simpleMde) {
        //simpleMde.codemirror.off('keyHandled', 'Shift-Enter')
      }
      setSimpleMdeInstance(simpleMde);
    }, []);

    useEffect(() => {
      if (simpleMdeInstance) {

        simpleMdeInstance.codemirror.setOption('extraKeys',{
          'Cmd-Enter': function(cm) {
            const cursorPos = simpleMdeInstance.codemirror.getCursor()
            const prefixText = simpleMdeInstance.codemirror.getRange(CodeMirror.Pos(0,0), cursorPos)
            const suffixText = simpleMdeInstance.codemirror.getRange(cursorPos, CodeMirror.Pos(100000,100000))
            props.completeKeyPressed(prefixText, suffixText)
            
            console.log(`Prefix: ${prefixText}`)
          }
        });
        console.log('binding keymaps')

        if (ref) ref.current = simpleMdeInstance
        console.info("Hey I'm editor instance!", simpleMdeInstance);
        simpleMdeInstance.codemirror.on('cursorActivity', function() {
          const cursorPos = simpleMdeInstance.codemirror.getCursor()
          const prefixText = simpleMdeInstance.codemirror.getRange(CodeMirror.Pos(0,0), cursorPos)
          const suffixText = simpleMdeInstance.codemirror.getRange(cursorPos, CodeMirror.Pos(100000,100000))
          if (props.onCursorChanged) {
            props.onCursorChanged(prefixText, suffixText)
          }
        })
      }  
    }, [simpleMdeInstance]);
  
  
    const autofocusNoSpellcheckerOptions = useMemo(() => {
      return {
        autofocus: shouldAutoFocus,
        spellChecker: false,
        status: false,
        indentWithTabs: false,
        maxHeight: maxHeight
      }
    }, []);
  
    return (
      <SimpleMDE 
        options={autofocusNoSpellcheckerOptions}
        value={props.value}
        onChange={props.onChange}
        getMdeInstance={getMdeInstanceCallback}
      />
    );
  });

export {MDEPreviewOnly, MDEEditable}