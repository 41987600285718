const defaultPSCText = {
  nameText:'Andrea',
  companyPillarsText: 'Impact, Cross-functional leadership, Engineering leadership',
  roleExpectationsText: 'E6 - Leads teams of engineers towards ambitious goals over multiple quarters. Recruits where necessary to fill in gaps for the team.',
  accomplishmentsText: `Projects\n\
\n\
Project: Ubiquity\n\
Your Role: Consulting engineer\n\
Your impact: Consulted on architecture decisions\n\
Project's impact: Project went much smoother because I unblocked the Status team\n\
\n\
Project: Lavalamp project\n\
Your Role: Lead engineer\n\
Your impact: Recruited Bill and Teri, wrote all the backend code and decided architecture. Built the product with eng and design. Led the entire team. Motivated team when things were shitty during COVID\n\
The project's impact: Lavalamp shipped to 1M customers on time!\n\
\n\
XFN leadership: Consulted with many teams on architecture projects.\n\
\n\
Other\n\
-Mentored Joey and Cindy on communication and architecture (on ubiquity project). Told them how to lead meeting, how to be good at code reviews.\n\
-Well-liked and often looked up to by peers for advice on engineering or product questions\n\
\n\
Areas for improvement:\n\
XFN communication could be improved. Andrea has dropped the ball on coordinating with Megazorp team. Caused friction and missed deadlines`
}

const defaultSQLTables = [{
  name:'admissions',
  schema:'\
patient_id	INT \n\
admission_date	DATE\n\
discharge_date	DATE\n\
diagnosis	VARCHAR(50)\n\
attending_doctor_id	INT'}
,
 {name:'patients',
  schema:'\
patient_id	INTEGER \n\
first_name	VARCHAR(30)\n\
last_name	VARCHAR(30)\n\
gender	CHAR(1)\n\
birth_date	DATE\n\
city	VARCHAR(30)\n\
province_id	CHAR(2)\n\
allergies	VARCHAR(80)\n\
height	DECIMAL(3, 0)\n\
weight	DECIMAL(4, 0)'},
{name:'doctors',
schema:'\
doctor_id	INTEGER\n\
first_name	VARCHAR(30)\n\
last_name	VARCHAR(30)\n\
specialty	VARCHAR(25)\n\
'}];

const defaultSQLQueryText = 'Get the top 5 doctors who have treated the most patients with bee allergies'

const defaultSQLExplainerText = `SELECT CONCAT(first_name, ' ', last_name) AS full_name,
LENGTH(CONCAT(first_name, ' ', last_name)) AS name_length
FROM patients
ORDER BY name_length DESC
LIMIT 1;`

const defaultLinterDocumentText = `
**PRD: changing the homescreen header**
=======================================

_TL;DR: We're updating the homepage header to be bigger and bolder, completed by April, 2022_

**Motivation**: We believe that by making the header splashier we can increase conversion to paid signupus and increase revenue.

**Requrements**
*   Update the homepage title to: "the world's greatest SaaS tool"
*   See mocks in Figma for the dimensions and background image
    
**Open questions and challenges**
*   Staffing: If we don't backfill Jerry, our timeline will slip by 2-3 weeks    
*   Accessibility: We have a dependency on A11y team's audit

**RAPID:**
Recommend: Web Homepage team
Approve: James (Design Dir.)
Perform: Web Homepage team
Informed: A11y team, web engineers, C-Team
Decide: Janna (Sr. Dir of product)

**Impact and testing schedule**
-AB test with 50% of traffic for 30 days
-Ship if: Results are neutral or positive in terms of revenue. Guardrail metrics: web clicks, SEO visits

**Cost/budget/timeline:**
-Est cost. will be 1 FE and 1 BE engineer for 3 weeks, with +1 week to polish and test
`

const defaultLinterRulesText = 
`What type of document is this?
Has author and team name?
Has cover page?
Indicates KPIs or key metrics?
Has strong motivation for the proposal?
Has goals of the project
Has cost/budget estimates
Has list of stakeholders
Has timeline for completion
Has list of any potential risks or challenges`

const defaultLinterRulesDict = 
  {'Product Requirements Document':{
   rules:`Has author and team name?
Has cover page?
Indicates KPIs or key metrics?
Has strong motivation for the proposal?
Goals of the project
Cost/budget estimates
List of stakeholders
Timeline for completion
List of any potential risks or challenges`
  },
  'TPS Report':
  {
   rules:`What type of document is this?
Does it have a cover page?`
  },
  'FINRA Comms with the Public':
  {
    rules:`Provide accurate and complete information.
Avoid making false or misleading statements.
Clearly identify any promotional content.
Disclose any fees, costs, or other material terms associated with products and services.
Ensure that advertising is fair and balanced and does not contain any exaggerated or unsubstantiated claims.
Ensure that customer communications are fair and clear, and do not contain any false or misleading statements.
Avoid using confusing or misleading language.
Ensure that advertising and customer communications do not target vulnerable or financially constrained customers.
Avoid using language that implies the customer will get something for nothing.
Fully disclose any conflict of interest.
`
  },
  'Investigative Journalism Article':
  {rules:``}
}


export {defaultLinterRulesDict, defaultLinterRulesText, defaultLinterDocumentText, defaultPSCText, defaultSQLTables, defaultSQLQueryText, defaultSQLExplainerText}